<template>
<v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `px-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
    <v-card-title class="px-0 break-word justify-space-between">
        {{meth.chapter}} :: {{method}}
    </v-card-title>
    <v-card-text class="px-0" v-html="meth.about">
    </v-card-text>
    <v-card-text class="px-0" v-if="meth.alert && !contactInfo.contractorId">
        <v-alert type="error" outlined dense class="caption mb-0">
            <div  v-html="meth.alert"></div>
        </v-alert>
    </v-card-text>
    <v-card-text class="pa-4 pa-md-6 pb-3 blue lighten-5">
        <p><b>Метод:</b> <b class="primary--text">{{method}}</b>, моделі <b class="primary--text">{{meth.model}}</b></p>
        <p><b>URL:</b> {{meth.method}} {{meth.url}}</p>
        <p><b>Формат:</b> <b class="primary--text">{{meth.format}}</b></p>
        <p><b>Доступність:</b> <span v-if="meth.authTokenReq"> За отриманим Токеном авторизації <b class="primary--text"><a @click="$router.push('/api-services/get-auth-token')">authToken</a></b></span><span v-else>Без обмеження</span></p>
    </v-card-text>
    <v-card-title class="px-0 break-word mt-4">
        Параметри запиту
    </v-card-title>
    <v-card-text class="px-0">
        <v-row no-gutters>
            <v-col cols="12" md="7" class="py-0">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left" width="25%">
                                    Параметр запиту
                                </th>
                                <th class="text-left" width="20%">
                                    Тип даних
                                </th>
                                <th class="text-left" width="55%">
                                    Опис
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in meth.params" :key="item.name">
                                <td :class="item.req ? `font-weight-bold primary--text`:``"> <span :class="item.level ? `pl-7`:``">{{item.name}}</span>
                                    <v-icon v-if="item.req" color="primary" class="mb-3">*</v-icon>
                                </td>
                                <td>{{ item.type }}</td>
                                <td v-html="item.descr"></td>
                            </tr>
                            <tr v-if="!meth.params.length">
                                <td colspan="3" class="body-2 grey--text text-center font-weight-light">Параметрів запиту немає</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-card-text>

    <v-card-text v-if="meth.paramsDescr" :class="`px-0 ` + (meth.paramsDescr ? `` : `py-0 `)">
        <p v-html="meth.paramsDescr"></p>
    </v-card-text>

    <v-card-title class="px-0 break-word">
        Заголовки HTTP
    </v-card-title>
    <v-card-text class="px-0">
        <v-row no-gutters>
            <v-col cols="12" md="7" class="py-0 mb-6">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left" width="25%">
                                    Заголовок
                                </th>
                                <th class="text-left" width="75%">
                                    Значення
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in meth.headers" :key="item.name">
                                <td :class="item.req ? `font-weight-bold primary--text`:``">{{item.name}}
                                    <v-icon v-if="item.req" color="primary" class="mb-3">*</v-icon>
                                </td>
                                <td v-html="item.value"></td>
                            </tr>
                            <tr v-if="!meth.headers.length">
                                <td colspan="3" class="body-2 grey--text text-center font-weight-light">Заголовків немає</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-card-text>

    <v-card-title v-if="!meth.skipTest" class="px-0 break-word justify-space-between">
        Перевірити роботу методу
    </v-card-title>
    <v-card-text v-if="!meth.skipTest" class="px-0">
        Для перевірки методу <span v-if="meth.params.length">заповніть <b>Параметри запиту</b>, та </span>натисніть <b class="primary--text">Надіслати</b>.
    </v-card-text>
    <h3 v-if="!meth.skipTest && meth.params.length">Параметри запиту</h3>
    <v-row no-gutters>
        <v-col cols="12" md="7" class="py-0">
            <v-row v-if="!meth.skipTest" no-gutters v-for="item in meth.params" :key="item.name">
                <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                    <v-text-field :label="item.name" v-model="params[item.name]"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
                <v-col cols="12" sm="12" lg="12" class="d-flex align-top">
                    <v-textarea no-resize rows="9" outlined :value="requestTxt" :label="meth.skipTest ? 'Приклад HTTP запиту' : 'HTTP запит'" type="text" readonly class="code"></v-textarea>
                    <v-btn icon @click="clickHandler(2)" title="Копіювати в буфер обміну" v-clipboard="() => requestTxt" v-clipboard:success="clipboardSuccessHandler">
                        <v-icon>{{copied[2] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="8" lg="6" class="d-flex align-center" v-if="!meth.skipTest">
                    <v-btn @click="postMethod()" color="primary" :loading="loaded">Надіслати</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-10">
                <v-col cols="12" sm="12" lg="12" class="d-flex align-top">
                    <v-textarea no-resize rows="9" outlined :value="responseTxt" :label="meth.skipTest ? 'Приклад HTTP відповіді' : 'HTTP відповідь'" readonly type="text" class="code"></v-textarea>
                    <v-btn icon @click="clickHandler(3)" title="Копіювати в буфер обміну" v-clipboard="() => responseTxt" v-clipboard:success="clipboardSuccessHandler">
                        <v-icon>{{copied[3] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                    </v-btn>

                </v-col>
            </v-row>

        </v-col>
    </v-row>
</v-card>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'

export default {
    components: {},
    directives: {},
    data: () => ({
        m: {
            'get-time': {
                about: 'Метод дозволяє отримати поточну дату та час серверу.',
                url: process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/open/get-time' : 'http://dev.yugcontract.ua:3033/api/open/get-time',
                authTokenReq: false,
                chapter: 'Авторизація',
                model: 'open',
                format: 'JSON',
                method: 'POST',
                params: [],
                headers: [{
                    name: 'Content-Type',
                    value: 'application/json',
                    req: true
                }]
            },
            'post-order': {
                about: 'Метод дозволяє сформувати та зарезервувати замовлення.',
                alert: 'Немає жодного доступного контрагента для створення замовлення.<br> Зверніться до закріпленого за вами менеджера.',
                chapter: 'Замовлення',
                url: process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/order/post-order' : 'http://dev.yugcontract.ua:3033/api/order/post-order',
                authTokenReq: true,
                model: 'order',
                format: 'JSON',
                method: 'POST',
                skipTest: true,
                params: [{
                        name: 'products',
                        type: 'Array',
                        descr: 'Перелік товарів замовлення',
                        req: true
                    },
                    {
                        name: 'typhoon_id',
                        type: 'String',
                        descr: 'Код товару. Унікальний ідентифікатор товару у каталозі В2В-порталу.',
                        level: 2
                    },
                    {
                        name: 'artikul',
                        type: 'String',
                        descr: 'Артикул товару',
                        level: 2
                    },
                    {
                        name: 'barcode',
                        type: 'String',
                        descr: 'Штрих - код товару',
                        level: 2
                    },
                    {
                        name: 'qty',
                        type: 'Number',
                        descr: 'Кількість товару',
                        level: 2
                    },
                    {
                        name: 'notes',
                        type: 'String',
                        descr: 'Примітки до товару: додаткова інформація, яку потрібно довести до менеджера',
                        level: 2
                    },
                    {
                        name: 'order_out_id',
                        type: 'Number',
                        descr: 'Номер відповідного замовлення Клієнта, якщо замовлення формується на підставі іншого замовлення у Клієнта. Унікальний з боку клієнта.',
                    },
                    {
                        name: 'comment',
                        type: 'String',
                        descr: 'Коментар до замовлення: додаткова інформація, яку потрібно довести до менеджера',
                    },
                    {
                        name: 'payment_type',
                        type: 'String',
                        descr: 'Тип сплати за замовлення. Значення: cash (готівка), bank (б/г). За замовчуванням - cash.'
                    },
                    {
                        name: 'mainoffice_warehouse',
                        type: 'Number',
                        descr: 'Пріоритет складів для резервування замовлення. Береться до уваги, якщо у користувача активовані відповідні повноваження. Значення: 0 (Резервування зі складу регіону) потім зі складу Києва), 1 (Резервування зі складу Києва, потім зі складу регіона), 2 (Резервування лише зі складу Києва). За замовчуванням - 0.'
                    },
                ],
                headers: [{
                        name: 'Content-Type',
                        value: 'application/json',
                        req: true
                    },
                    {
                        name: 'Authorization',
                        value: 'Bearer <b class="primary--text">authToken</b>',
                        req: true
                    },
                ],
                request: {
                    "order_out_id": 12345678,
                    "comment": "Test Order. Удалить!",
                    "payment_type": "cash",
                    "mainoffice_warehouse": 0,
                    "products": [{
                            "typhoon_id": 6409800,
                            "qty": 1,
                            "notes": "Відповідно коду товару"
                        },
                        {
                            "typhoon_id": 6469758,
                            "qty": 1,
                            "notes": "Відповідно коду товару"
                        },
                        {
                            "typhoon_id": 12345,
                            "qty": 1,
                            "notes": "Відповідно коду товару"
                        },
                        {
                            "artikul": 2345,
                            "qty": 2,
                            "notes": "Відповідно Артикулу товару"
                        },
                        {
                            "barcode": 414182839949555600,
                            "qty": 1,
                            "notes": "Відповідно Штрих-коду товару"
                        }
                    ]
                },
                response: {
                    "method": "/api/order/post-order",
                    "status": "ok",
                    "error": null,
                    "content": {
                        "orderId": "2558263",
                        "faultyProducts": [{
                            "artikul": null,
                            "barcode": null,
                            "typhoon_id": 12345
                        }, {
                            "typhoon_id": null,
                            "barcode": 414182839949555600,
                            "artikul": null
                        }],
                        "orderProducts": [{
                            "typhoon_id": 6409800,
                            "artikul": "R14-2S",
                            "barcode": "6900064098002",
                            "order_id": 2558263,
                            "doc_id": "185571917",
                            "qty": 1,
                            "reserved_qty": 1
                        }, {
                            "typhoon_id": 3120423,
                            "artikul": "2345",
                            "barcode": "",
                            "order_id": 2558263,
                            "doc_id": "",
                            "qty": 2,
                            "reserved_qty": 0
                        }, {
                            "typhoon_id": 6469758,
                            "artikul": "LR6-20S",
                            "barcode": "6900064697588",
                            "order_id": 2558263,
                            "doc_id": "",
                            "qty": 1,
                            "reserved_qty": 0
                        }]
                    }
                },
                paramsDescr: `
                <p>Товар замовлення визначається за одним з ідентифікаторів у такому порядку: typhoon_id, artikul, barcode. Тому достатньо мати у параметрах хоч один з них.</p>
                <p><h3>Значення, що повертаються у відповіді:</h3></p>
                <b>status</b> - результат створення замовлення (ok = замовлення оброблено успішно; error = помилка);<br>
                <b>orderId</b> - номер створеного замовлення В2В-порталу;<br>
                <b>orderProducts</b> - перелік товарів із запиту, які було успішно розпізнано та додано у замовлення (<b>reserved_qty</b> - кількість, яку вдалося резервувати, <b>doc_id</b> - номер документу резервування (на одне замовлення може бути створено декілька документів резервування));<br>
                <b>faultyProducts</b> - перелік некоректних кодів товарів, які не було розпізнано. Ці товари не додавалися у замовлення.
                `
            },
            'confirm-order': {
                about: 'Метод використовується для заповнення реквізитів доставки замовлення та підтвердження його відвантаження.',
                url: process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/order/confirm-order' : 'http://dev.yugcontract.ua:3033/api/order/confirm-order',
                authTokenReq: true,
                chapter: 'Замовлення',
                model: 'order',
                format: 'JSON',
                method: 'POST',
                params: [{
                        name: 'order_id',
                        type: 'Number',
                        descr: 'Номер замовлення',
                        req: true
                    },
                    {
                        name: 'order_out_id',
                        type: 'Number',
                        descr: 'Номер відповідного замовлення Клієнта, якщо замовлення сформовано на підставі іншого замовлення у Клієнта',
                        req: true
                    },
                    {
                        name: 'delivery_type',
                        type: 'Number',
                        descr: 'Спосіб доставки:<br>0 – Самовивіз,<br>1 – Доставка до складу перевізника,<br>2 – Доставка до адреси отримувача,<br>3 – Drop-shipping',
                        req: false
                    },
                    {
                        name: 'delivery_transport',
                        type: 'Number',
                        descr: 'Перевізник:<br>-1 – Транспорт ЮК,<br>1 – Нова пошта,<br>33 – Укрпошта',
                        req: false
                    },
                    // {
                    //     name: 'cash_on_delivery',
                    //     type: 'Number',
                    //     descr: 'Оплата при отриманні:<br>0 – без післяплати,<br>1 – з післяплати',
                    //     req: false
                    // },
                    {
                        name: 'order_ttn',
                        type: 'String',
                        descr: 'ТТН перевізника для доставки Drop-shipping',
                        req: false
                    },
                    {
                        name: 'rcpt_id',
                        type: 'Number',
                        descr: 'Номер вантажоодержувача (отримати можна у розділі <a href="/delivery-points" target="_blank">Точки доставки</a>)',
                        req: false
                    },
                    {
                        name: 'rcpt_warehouse',
                        type: 'Number',
                        descr: 'Номер склада перевізника (у кодах перевізника)',
                        req: false
                    },
                ],
                headers: [{
                        name: 'Content-Type',
                        value: 'application/json',
                        req: true
                    },
                    {
                        name: 'Authorization',
                        value: 'Bearer <b class="primary--text">authToken</b>',
                        req: true
                    },
                ],
                paramsDescr: `
                <p>Замовлення визначається за одним з ідентифікаторів <b>order_id</b> або <b>order_out_id</b>. Тому достатньо мати у параметрах хоч один з них.</p>
                <p>Спосіб доставки <b>Drop-shipping</b> можливо використовувати лише за наявності доступу до нього на порталі!</p>
                `
            },
            'delete-order': {
                about: 'Метод використовується для видалення замовлення.',
                url: process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/order/delete-order' : 'http://dev.yugcontract.ua:3033/api/order/delete-order',
                authTokenReq: true,
                chapter: 'Замовлення',
                model: 'order',
                format: 'JSON',
                method: 'POST',
                params: [{
                        name: 'order_id',
                        type: 'Number',
                        descr: 'Номер замовлення',
                        req: true
                    },
                    {
                        name: 'order_out_id',
                        type: 'Number',
                        descr: 'Номер відповідного замовлення Клієнта, якщо замовлення сформовано на підставі іншого замовлення у Клієнта',
                        req: true
                    },
                ],
                headers: [{
                        name: 'Content-Type',
                        value: 'application/json',
                        req: true
                    },
                    {
                        name: 'Authorization',
                        value: 'Bearer <b class="primary--text">authToken</b>',
                        req: true
                    },
                ],
                paramsDescr: `
                <p>Замовлення визначається за одним з ідентифікаторів <b>order_id</b> або <b>order_out_id</b>. Тому достатньо мати у параметрах хоч один з них.</p>
                <p><h3>Значення, що повертаються у відповіді:</h3></p>
                <b>status</b> - результат видалення (ok - успішно; error - не успішно).
                `
            },
            'get-order-info': {
                about: 'Метод дозволяє отримати актуальну інформацію про товарне наповнення замовлення.',
                url: process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/order/get-order-info' : 'http://dev.yugcontract.ua:3033/api/order/get-order-info',
                authTokenReq: true,
                chapter: 'Звіти',
                model: 'order',
                format: 'JSON',
                method: 'POST',
                params: [{
                        name: 'order_id',
                        type: 'Number',
                        descr: 'Номер замовлення',
                        req: true
                    },
                    {
                        name: 'order_out_id',
                        type: 'Number',
                        descr: 'Номер відповідного замовлення Клієнта, якщо замовлення сформовано на підставі іншого замовлення у Клієнта.',
                        req: true
                    },
                ],
                headers: [{
                        name: 'Content-Type',
                        value: 'application/json',
                        req: true
                    },
                    {
                        name: 'Authorization',
                        value: 'Bearer <b class="primary--text">authToken</b>',
                        req: true
                    },
                ],
                paramsDescr: `
                <p>Замовлення визначається за одним з ідентифікаторів <b>order_id</b> або <b>order_out_id</b>. Тому достатньо мати у параметрах хоч один з них.</p>
                <p><h3>Значення, що повертаються у відповіді:</h3></p>
                <b>status</b> - результат виконання запиту (ok = замовлення знайдено; error = помилка);<br>
                <b>orderId</b> - номер знайденого замовлення В2В-порталу;<br>
                <b>products</b> - поточний перелік товарів замовлення(<b>typhoon_id</b> - код товару, <b>qty</b> - поточна кількість товару у резерві, <b>price</b> - ціна товару).
                `
            },
            'get-categories': {
                about: 'Метод дозволяє отримати структуру Загального Товарного каталогу В2В-портала.',
                url: process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/catalog/get-categories' : 'http://dev.yugcontract.ua:3033/api/catalog/get-categories',
                authTokenReq: true,
                chapter: 'Контент',
                model: 'catalog',
                format: 'JSON',
                method: 'POST',
                params: [],
                headers: [{
                        name: 'Content-Type',
                        value: 'application/json',
                        req: true
                    },
                    {
                        name: 'Authorization',
                        value: 'Bearer <b class="primary--text">authToken</b>',
                        req: true
                    },
                ],
            },
            'get-order-sn': {
                about: 'Метод дозволяє отримати SN товарів замовлення.',
                url: process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/order/get-order-sn' : 'http://dev.yugcontract.ua:3033/api/order/get-order-sn',
                authTokenReq: true,
                chapter: 'Звіти',
                model: 'order',
                format: 'JSON',
                method: 'POST',
                params: [{
                        name: 'order_id',
                        type: 'Number',
                        descr: 'Номер замовлення',
                        req: true
                    },
                    {
                        name: 'order_out_id',
                        type: 'Number',
                        descr: 'Номер відповідного замовлення Клієнта, якщо замовлення сформовано на підставі іншого замовлення у Клієнта',
                        req: true
                    },
                ],
                headers: [{
                        name: 'Content-Type',
                        value: 'application/json',
                        req: true
                    },
                    {
                        name: 'Authorization',
                        value: 'Bearer <b class="primary--text">authToken</b>',
                        req: true
                    },
                ],
                paramsDescr: `
                <p>Замовлення визначається за одним з ідентифікаторів <b>order_id</b> або <b>order_out_id</b>. Тому достатньо мати у параметрах хоч один з них.</p>
                <p><h3>Значення, що повертаються у відповіді:</h3></p>
                <b>product_id</b> - код товару;<br>
                <b>product_artikul</b> - артикул товару;<br>
                <b>doc_id</b> - номер документа резервування, у якому знаходиться товар;<br>
                <b>sn</b> - серійний номер зарезервованого товару;<br>
                <b>qty</b> - кількість товару у документі за серійним номером;<br>
                `
            },
            'get-doc-sn': {
                about: 'Метод дозволяє отримати SN товарів конкретного документу замовлення.',
                url: process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/order/get-doc-sn' : 'http://dev.yugcontract.ua:3033/api/order/get-doc-sn',
                authTokenReq: true,
                chapter: 'Звіти',
                model: 'order',
                format: 'JSON',
                method: 'POST',
                params: [{
                    name: 'doc_id',
                    type: 'Number',
                    descr: 'Номер документу замовлення',
                    req: true
                }, ],
                headers: [{
                        name: 'Content-Type',
                        value: 'application/json',
                        req: true
                    },
                    {
                        name: 'Authorization',
                        value: 'Bearer <b class="primary--text">authToken</b>',
                        req: true
                    },
                ],
                paramsDescr: `
                <p class="primary--text">Метод <b>deprecated</b>. Скористайтеся новим методом <a href="/api/sales/get-doc-info"><b>get-doc-info</b></a></p>
                <p><h3>Значення, що повертаються у відповіді:</h3></p>
                <b>product_id</b> - код товару;<br>
                <b>product_artikul</b> - артикул товару;<br>
                <b>doc_id</b> - номер документа резервування, у якому знаходиться товар;<br>
                <b>sn</b> - серійний номер зарезервованого товару;<br>
                <b>qty</b> - кількість товару у документі за серійним номером;<br>
                `
            },
            'get-sales-goods': {
                about: 'Метод дозволяє отримати інформацію про відвантаження товарів за період (аналог розділу "Історія товарного обігу").',
                url: process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/sales/get-sales-goods' : 'http://dev.yugcontract.ua:3033/api/sales/get-sales-goods',
                authTokenReq: true,
                chapter: 'Звіти',
                model: 'sales',
                format: 'JSON',
                method: 'POST',
                params: [{
                        name: 'date_from',
                        type: 'Date (YYYY-MM-DD)',
                        descr: 'Дата початку періоду',
                        req: false
                    },
                    {
                        name: 'date_to',
                        type: 'Date (YYYY-MM-DD)',
                        descr: 'Дата кінця періоду (включно)',
                        req: false
                    },
                ],
                headers: [{
                        name: 'Content-Type',
                        value: 'application/json',
                        req: true
                    },
                    {
                        name: 'Authorization',
                        value: 'Bearer <b class="primary--text">authToken</b>',
                        req: true
                    },
                ],
                paramsDescr: `
                <p><h3>Значення, що повертаються у відповіді:</h3></p>
                        <b>name</b> - Назва контрагента, на якого було відвантаження;<br>
                        <b>id</b> - Код контрагента, на якого було відвантаження.<br>
                        <b>documents - документи, які були відвантажені на контрагента:</b><br>
                        <b>id</b> - номер документа;<br>
                        <b>date</b> - дата документа;<br>
                        <b>ho_id</b> - номер хоз. операції;<br>
                        <b>ho_name</b> - назва хоз. операції;<br>
                        <b>currency_id</b> - код валюти;<br>
                        <b>reciever_id</b> - код вантажоотримувача;<br>
                        <b>reciever_fio</b> - ПІБ вантажоотримувача.<br>
                        <b>products - товари, які були відвантажені у документі:</b><br>
                        <b>id</b> - код;<br>
                        <b>artikul</b> - артікул;<br>
                        <b>brand</b> - бренд;<br>
                        <b>name</b> - назва;<br>
                        <b>qty</b> - кількість;<br>
                        <b>price</b> - ціна;<br>
                        <b>price_sum</b> - вартість.<br>
                `
            },
            'get-sales': {
                about: 'Метод дозволяє отримати інформацію про замовлення за період (аналог розділу "Історія замовлень").',
                url: process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/sales/get-sales' : 'http://dev.yugcontract.ua:3033/api/sales/get-sales',
                authTokenReq: true,
                chapter: 'Звіти',
                model: 'sales',
                format: 'JSON',
                method: 'POST',
                params: [{
                        name: 'date_from',
                        type: 'Date (YYYY-MM-DD)',
                        descr: 'Дата початку періоду',
                        req: false
                    },
                    {
                        name: 'date_to',
                        type: 'Date (YYYY-MM-DD)',
                        descr: 'Дата кінця періоду (включно)',
                        req: false
                    },
                    {
                        name: 'status',
                        type: 'String',
                        descr: 'Поточний статус обробки замовлення:<br>inwork - В обробці;<br>completed - Сформования;<br>delivery - У доставці;<br>closed - Завершений;<br>canceled - Скасований.',
                        req: false
                    },
                    {
                        name: 'doc_id',
                        type: 'Number',
                        descr: 'Номер документа',
                        req: false
                    },
                    {
                        name: 'payment',
                        type: 'Number',
                        descr: 'Форма оплати: <br>14 - Безготівкова;<br>4 - Інша.',
                        req: false
                    },
                ],
                headers: [{
                        name: 'Content-Type',
                        value: 'application/json',
                        req: true
                    },
                    {
                        name: 'Authorization',
                        value: 'Bearer <b class="primary--text">authToken</b>',
                        req: true
                    },
                ],
                paramsDescr: `
                <p><h3>Значення, що повертаються у відповіді:</h3></p>
                        <b>id</b> - основний документ;<br>
                        <b>id_transit</b> - транзитний документ;<br>
                        <b>date</b> - дата основного документа (Дата продажу);<br>
                        <b>date_transit</b> - дата транзитного документа (Дата замовлення);<br>
                        <b>status</b> - поточний статус обробки замовлення;<br>
                        <b>currency_id</b> - код форми оплати (14 - безготівков; 4 - інша);<br>
                        <b>delivery_transport</b> - перевізник;<br>
                        <b>delivery_address</b> - адреса доставки;<br>
                        <b>delivery_ttn</b> - номер декларації;<br>
                        <b>reciever_id</b> - код вантажоотримувача;<br>
                        <b>reciever_fio</b> - ПІБ вантажоотримувача, телефон;<br>
                        <b>rn</b> - Номер РН;<br>
                        <b>buyer</b> - Юр. особа покупця.<br>

                `
            },
            'get-doc-info': {
                about: 'Метод дозволяє отримати актуальну інформацію про товарне наповнення документа відвантаження з вказанням СН товарів, якщо документ вже був відвантажений зі складу.',
                url: process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/sales/get-doc-info' : 'http://dev.yugcontract.ua:3033/api/sales/get-doc-info',
                authTokenReq: true,
                chapter: 'Звіти',
                model: 'sales',
                format: 'JSON',
                method: 'POST',
                params: [
                    {
                        name: 'doc_id',
                        type: 'Number',
                        descr: 'Номер документа',
                        req: false
                    }
                ],
                headers: [{
                        name: 'Content-Type',
                        value: 'application/json',
                        req: true
                    },
                    {
                        name: 'Authorization',
                        value: 'Bearer <b class="primary--text">authToken</b>',
                        req: true
                    },
                ],
                paramsDescr: `
                <p><h3>Значення, що повертаються у відповіді:</h3></p>
                        <b>id</b> - код товару;<br>
                        <b>name</b> - назва товару;<br>
                        <b>artikul</b> - артикул товару;<br>
                        <b>ukt</b> - Код УКТ ЗЕД;<br>
                        <b>brand</b> - бренд товару;<br>
                        <b>qty</b> - кількість товару в документі;<br>
                        <b>price</b> - ціна товару в документі;<br>
                        <b>price_sum</b> - сума по товару в документі;<br>
                        <b>sn</b> - серійні номери (SN) товару, які були відвантажені.<br>
                `
            }
        },

        response: ``,
        copied: {},
        request: ``,
        loaded: false,
        items: [],
        show1: true,
        codeTxt: ``,
        params: {}
    }),
    props: {},
    methods: {
        ...mapActions(['setSnackbar', 'touch', 'postMethodRequest']),
        ...mapGetters(['catalogTree']),
        clickHandler(v) {
            this.$set(this.copied, v, true)
            setTimeout(() => {
                this.copied[v] = false
            }, 1000);
        },
        clipboardSuccessHandler() {
            this.setSnackbar(['success', 'Скопійовано в буфер обміну'])
        },
        getLocalStorage(f) {
            let value = ''
            try {
                value = localStorage[f] ? JSON.parse(localStorage[f]) : ''
            } catch (error) {
                console.log(error)
                return ''
            }
            return value
        },
        setLocalStorage(f, v) {
            localStorage[f] = JSON.stringify(v)
        },
        postMethod() {
            const requestToken = this.requestToken
            this.loaded = true
            const params = this.params
            this.postMethodRequest({
                    method: '/' + this.meth.model + '/' + this.method,
                    params
                })
                .then(res => {
                    this.setSnackbar(['success', '200 ОК'])
                    this.response = JSON.stringify(res, null, 4)
                    this.loaded = false
                })
                .catch(error => {
                    console.log(error)
                    this.response = JSON.stringify({
                        status: 'error',
                        error: error.message
                    }, null, 4)
                    this.loaded = false
                })
        },
    },
    computed: {
        method() {
            return this.$route.params.method
        },
        meth() {
            return this.m[this.method]
        },
        authToken() {
            return this.getLocalStorage('authToken')
        },
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        requestTxt() {
            const req = `POST ${this.meth.url} HTTP/1.0
Content-Type: application/json
` +
                (this.meth.authTokenReq ? `Authorization: Bearer ${this.authToken}

` : `
`)
            return req + (this.meth.request ? JSON.stringify(this.meth.request, null, 4) : JSON.stringify(this.params, null, 4))
        },
        responseTxt() {
            return this.response ? this.response : JSON.stringify(this.meth.response, null, 4)
        }
    },
    created() {
        this.$store.commit('setLoading', false)
    }
}
</script>

<style>
</style>
